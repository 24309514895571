import React from 'react'
import { PageProps, graphql } from 'gatsby'
import Layout from '../components/Layout'
import fishfail from '../images/fishfail.gif'

const heroStyle = {
  backgroundImage: `url(${fishfail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center bottom',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh'
}

export default function PageGatsby404(props: PageProps) {
  const {
    title,
    description,
    actions
  } = (props.data as any).content.edges[0].node.childPagesJson

  return (
    <Layout
      title={title}
      description={description}
      uri={props.uri}
      location={props.location}
      heroCenter
      heroDark
      heroStyle={heroStyle}
      heroActions={actions}
    >
      &nbsp;
    </Layout>
  )
}

export const contentQuery = graphql`
  query Page404Query {
    content: allFile(filter: { relativePath: { eq: "pages/404.json" } }) {
      edges {
        node {
          childPagesJson {
            title
            description
            actions {
              title
              url
            }
          }
        }
      }
    }
  }
`
